import { trackLegacyEvent } from "@analytics";
import {
  Box,
  CarouselSimple,
  type CarouselSimpleProps,
  ShimmerBox,
} from "@biom3/react";
import ContainerBox from "@components/Container";
import { TILE_DIMENSIONS } from "@hooks/tile-dimensions";
import { PromoTileType, QuestTileType } from "@imx-tokens-ts/gems-game/domain";
import type { EmblaCarouselType } from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

export type CarouselContainerProps = {
  children: React.ReactNode;
  tileType: QuestTileType | PromoTileType;
  autoplay?: boolean;
  isLoading?: boolean;
  scrollToIndex?: number;
} & CarouselSimpleProps;

const LoadingState = ({
  tileType,
}: {
  tileType: QuestTileType | PromoTileType;
}) => {
  const height = TILE_DIMENSIONS[tileType].height;
  const width = TILE_DIMENSIONS[tileType].width;
  const shimmerBoxCount = tileType === QuestTileType.FeaturedQuest ? 1 : 2;

  return (
    <ContainerBox
      sx={{
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
      }}
      testId={`${tileType}__loading`}
    >
      {Array.from({ length: shimmerBoxCount }).map((_, index) => (
        <ShimmerBox
          // biome-ignore lint/suspicious/noArrayIndexKey: There is no other unique key to use here
          key={`shimmer-box-${tileType}-${index}`}
          sx={{
            flexShrink: 0,
            height,
            width,
            ml: index !== 0 ? "base.spacing.x6" : "0px",
          }}
        />
      ))}
    </ContainerBox>
  );
};

const toUserJourney = (tileType: QuestTileType | PromoTileType) => {
  switch (tileType) {
    case QuestTileType.FeaturedQuest:
      return "FeaturedQuests";
    case QuestTileType.MainQuest:
      return "MainQuests";
    case QuestTileType.SideQuest:
      return "SideQuests";
    case QuestTileType.OnboardingQuest:
      return "OnboardingQuests";
    case PromoTileType.PromoTile:
      return "PromoTiles";
    default:
      console.error(`Unknown tileType: ${tileType}`);
  }
};

const Container = ({
  children,
  tileType,
  scrollToIndex,
  isLoading,
  autoplay,
  getApi,
  ...carouselProps
}: CarouselContainerProps) => {
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);

  let plugins: CarouselSimpleProps["plugins"];

  if (autoplay) {
    plugins = [
      Autoplay({
        playOnInit: true,
        delay: 5000,
        stopOnInteraction: false,
        stopOnMouseEnter: true,
        stopOnFocusIn: true,
      }),
    ];
  }

  useEffect(() => {
    if (emblaApi && scrollToIndex !== undefined) {
      const { slideRegistry } = emblaApi.internalEngine();
      const snapIndexThatSlideBelongsTo = slideRegistry.findIndex((group) =>
        group.includes(scrollToIndex),
      );
      if (typeof snapIndexThatSlideBelongsTo !== "undefined") {
        emblaApi.scrollTo(snapIndexThatSlideBelongsTo);
      }
    }
  }, [scrollToIndex, emblaApi]);

  if (isLoading) return <LoadingState tileType={tileType} />;

  function handleNavigationClick(type: "Prev" | "Next" | "Navigation") {
    trackLegacyEvent({
      screen: "QuestsNavigation",
      userJourney: toUserJourney(tileType) ?? "",
      action: "Pressed",
      control: type,
      controlType: "Button",
    });
  }

  return (
    <Box
      testId={`${tileType}__carousel`}
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <CarouselSimple
        slidesToScroll={"auto"}
        highlightActiveSlide={false}
        getApi={(api) => {
          setEmblaApi(api);
          getApi?.(api);
        }}
        controlBarSx={{
          justifyContent: "start",
          gap: "base.spacing.x4",
        }}
        plugins={plugins}
        loop={autoplay}
        {...carouselProps}
      >
        {children}
        <CarouselSimple.PreviousButtCon
          testId={`${tileType}__prev`}
          size="medium"
          onClick={() => handleNavigationClick("Prev")}
        />
        <CarouselSimple.NextButtCon
          testId={`${tileType}__next`}
          size="medium"
          onClick={() => handleNavigationClick("Next")}
          sx={{
            mr: "base.spacing.x2",
          }}
        />
        <CarouselSimple.Pagination
          onClick={() => handleNavigationClick("Navigation")}
          testId={`${tileType}__navigation`}
        />
      </CarouselSimple>
    </Box>
  );
};

// no idea why nextjs would complain about hydration issues for this component
const CarouselContainer = dynamic(() => Promise.resolve(Container), {
  ssr: false,
});

export { CarouselContainer };
