import { Body, Box, Divider, Heading } from "@biom3/react";
import Container from "@components/Container";

export type HeaderProps = {
  testId: string;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  showDivider?: boolean;
};

export function Header({
  title,
  description,
  testId,
  showDivider,
}: HeaderProps) {
  return (
    <Container testId={`${testId}__header`}>
      <Divider
        sx={{
          position: "relative",
          display: showDivider ? "flex" : "none",
          mb: "base.spacing.x4",
        }}
      />
      <Box
        sx={{
          mb: "base.spacing.x6",
          position: "relative",
        }}
      >
        <Heading
          weight="bold"
          size="medium"
          testId={`${testId}__title`}
          sx={{ zIndex: 1 }}
        >
          {title}
        </Heading>
        {description ? (
          <Body
            size="small"
            weight="regular"
            color="base.text.secondary"
            testId={`${testId}__description`}
          >
            {description}
          </Body>
        ) : null}
      </Box>
    </Container>
  );
}
