import {
  getDiscoverFeed,
  getQuestSections,
} from "@/domain/gems-game/data-access/lib/cms";
import { appConfig } from "@constants";
import { getPromoTiles, getQuests } from "@imx-tokens-ts/gems-game/data-access";
import useSWR, { preload, type SWRConfiguration } from "swr";

type Props = {
  isFetchingLatestEarningsPaused: boolean;
};

export const QUESTS_DATA_KEY = "/quests";
export const QUESTS_SECTIONS_DATA_KEY = "/quest_sections";
export const QUESTS_PROMO_DATA_KEY = "/quest_promo_tiles";
export const DISCOVER_FEED_DATA_KEY = "/discover_feed";

export const usePromoTiles = ({ isFetchingLatestEarningsPaused }: Props) =>
  useSWR(isFetchingLatestEarningsPaused ? null : QUESTS_PROMO_DATA_KEY, () =>
    getPromoTiles(fetch, appConfig.PROMO_TILES_CMS_URL),
  );

export const useQuests = () =>
  useSWR(QUESTS_DATA_KEY, () => getQuests(fetch, appConfig.QUESTS_CMS_URL));

export const useQuestSections = () =>
  useSWR(QUESTS_SECTIONS_DATA_KEY, () =>
    getQuestSections(fetch, appConfig.QUEST_SECTIONS_CMS_URL),
  );

export const useDiscoverFeed = (swrConfig?: SWRConfiguration) =>
  useSWR(
    DISCOVER_FEED_DATA_KEY,
    () => getDiscoverFeed(fetch, appConfig.DISCOVER_FEED_CMS_URL),
    swrConfig,
  );

export const preloadAllQuestCMS = () => {
  preload(QUESTS_DATA_KEY, () => getQuests(fetch, appConfig.QUESTS_CMS_URL));
  preload(QUESTS_SECTIONS_DATA_KEY, () =>
    getQuestSections(fetch, appConfig.QUEST_SECTIONS_CMS_URL),
  );
  preload(QUESTS_PROMO_DATA_KEY, () =>
    getPromoTiles(fetch, appConfig.PROMO_TILES_CMS_URL),
  );
  preload(DISCOVER_FEED_DATA_KEY, () =>
    getDiscoverFeed(fetch, appConfig.DISCOVER_FEED_CMS_URL),
  );
};
