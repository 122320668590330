import { NEXT_IMAGE_QUALITY, QUEST_CAROUSEL_IMAGE_SIZES } from "@/constants";
import { useFlag } from "@/hooks/useFlags";
import { useQuestAnalytics } from "@/hooks/useQuestAnalytics";
import {
  Body,
  Box,
  Button,
  HeroCard,
  Icon,
  Stack,
  lineClamp,
} from "@biom3/react";
import {
  type LatestEarnings,
  type Quest,
  getBadgeProps,
} from "@imx-tokens-ts/gems-game/domain";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { type Ref, useState } from "react";
import { questMediaURL } from "../utils";
import { MultiLine, MultiLineWithBadge } from "./Multiline";
import { RulesDialog } from "./RulesDialog/RulesDialog";
import {
  type PrimaryTileAction,
  type SecondaryTileAction,
  useGetTileActions,
} from "./actions/tile-actions";
import { regularVariant } from "./utils";

export type QuestTileProps = {
  quest: Quest;
  latestEarnings: LatestEarnings | undefined;
  videoDomRef?: Ref<HTMLVideoElement>;
  isFirstQuest?: boolean;
};

export const QuestTile = ({
  quest,
  latestEarnings,
  videoDomRef,
  isFirstQuest = false,
}: QuestTileProps) => {
  const analytics = useQuestAnalytics(quest);
  const router = useRouter();
  const [showRules, setShowRules] = useState(false);
  const dedicatedQuestDetailPageEnabled = useFlag(
    "engagementDedicatedQuestDetailPage",
  );

  const idPrefix = quest.id;
  const hasCompleted = quest.hasCompleted(latestEarnings);
  const { primaryActions, secondaryActions } = useGetTileActions(
    quest,
    hasCompleted,
  );
  const isOnboarding = quest.isOnboardingQuest();
  const badgeProps = getBadgeProps({
    startDate: quest.startDate,
    endDate: quest.endDate,
  });

  function handleButtonClick(action: PrimaryTileAction | SecondaryTileAction) {
    if (action.link) {
      return analytics.trackCtaClick({
        control: "label" in action ? action.label : action.action,
        controlType: "Link",
        screen: "QuestTile",
        extras: {
          link: action.link?.toString(),
        },
      });
    }

    if (dedicatedQuestDetailPageEnabled && quest.dedicatedQuestPageId) {
      router.push(`/quests/${quest.dedicatedQuestPageId}`);
      return;
    }

    setShowRules(true);
    return analytics.trackCtaClick({
      control: "Rules",
      controlType: "Button",
      screen: "QuestTile",
    });
  }

  const earnedGemAmount =
    (hasCompleted
      ? quest.isOnboardingQuest()
        ? quest.gemsAmount
        : latestEarnings?.totalEarningsForRule(quest.id)
      : null) || "…";

  return (
    <>
      <HeroCard
        testId={`${idPrefix}-tile`}
        size="small"
        aspectRatio="unset"
        sx={{
          "& > .innerContentContainer": {
            height: "65%",
            minh: "65%",
          },
          width: "100%",
          height: "100%",
          minHeight: "275px", // Needed for Cypress tests where the images don't render.
        }}
      >
        <HeroCard.FramedImage
          imageUrl={questMediaURL(quest.logo).toString()}
          size="large"
        />
        {quest.backgroundVideoUrl ? (
          <HeroCard.AssetVideo
            testId={`${idPrefix}__video`}
            sx={{ height: "100%" }}
            mimeType={quest.backgroundVideoUrl.mimeType}
            videoUrl={quest.backgroundVideoUrl.url.toString()}
            domRef={videoDomRef}
            autoPlay={false}
            muted
          />
        ) : (
          <HeroCard.AssetImage
            testId={`${idPrefix}__image`}
            loading={isFirstQuest ? "eager" : "lazy"}
            use={
              <Image
                src={questMediaURL(quest.backgroundMediaUrl).toString()}
                alt={quest.caption}
                fill
                priority={isFirstQuest}
                quality={NEXT_IMAGE_QUALITY}
                sizes={QUEST_CAROUSEL_IMAGE_SIZES}
                style={{ objectFit: "cover" }}
              />
            }
          />
        )}
        <HeroCard.Caption testId={`${idPrefix}__caption`}>
          {quest.caption}
        </HeroCard.Caption>
        <HeroCard.Title
          size={quest.isFeatured() ? ["medium", "medium", "large"] : "medium"}
          testId={`${idPrefix}__title`}
          sx={{ ...lineClamp(10) }} // set this to a higher number like 10 so it never shows ... on the title. Default by Biome is 3
        >
          <MultiLine lines={quest.title} testId={`${idPrefix}__title`} />
        </HeroCard.Title>
        <HeroCard.Description testId={`${idPrefix}__description`}>
          <MultiLineWithBadge
            badgeProps={badgeProps}
            lines={quest.combinedDescription}
            testId={`${idPrefix}__description`}
          />
        </HeroCard.Description>

        {hasCompleted ? (
          <HeroCard.Button
            testId={`${idPrefix}__completed`}
            sx={{
              backgroundColor: "#00FF94",
              color: "#131313",
            }}
            variant="tertiary"
            disabled={true}
          >
            <Box>{earnedGemAmount} Gems</Box>
            <Button.Icon
              icon={"Tick"}
              fontWeight="bold"
              sx={{ w: { default: "base.icon.size.200" }, fill: "#131313" }}
            />
          </HeroCard.Button>
        ) : null}

        {/* Primary actions */}
        {primaryActions.map((action, i) =>
          isOnboarding && action.action === "PlayGame" && action.link ? (
            <HeroCard.Button
              size="large"
              variant="primary"
              key={action.action}
              sx={{
                justifyContent: "stretch",
                ".Button__innerSpan": { w: "100%" },
                ".Button__innerSpan__innerContent": { w: "100%" },
              }}
              rc={
                <Link
                  href={action.link}
                  onClick={() => handleButtonClick(action)}
                  referrerPolicy="same-origin"
                  target="_self"
                />
              }
            >
              <Button.Icon icon="Add" iconVariant="bold" />
              <Stack gap="0px">
                <Body sx={{ c: "inherit" }} weight="bold">
                  {action.label}
                </Body>
                <Body
                  size="xSmall"
                  sx={{
                    c: "inherit",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  +{quest.gemsAmount}
                  <Icon
                    icon="ImxRewards"
                    sx={{
                      fill: "inherit",
                      w: "base.icon.size.100",
                      mr: "base.spacing.x1",
                    }}
                    variant="bold"
                  />
                  gems
                </Body>
              </Stack>
            </HeroCard.Button>
          ) : (
            <HeroCard.Button
              key={action.action}
              testId={`${idPrefix}__primary-${i}`}
              variant={i === 0 ? "primary" : "tertiary"}
              {...(action.link
                ? quest.isImmutablePlayLink()
                  ? {
                      rc: (
                        <Link
                          href={action.link}
                          onClick={() => handleButtonClick(action)}
                          referrerPolicy="same-origin"
                          target="_self"
                        />
                      ),
                    }
                  : {
                      rc: (
                        <a
                          href={action.link?.toString()}
                          onClick={() => handleButtonClick(action)}
                          target="_blank"
                          rel="noreferrer"
                        />
                      ),
                    }
                : { onClick: () => handleButtonClick(action) })}
            >
              {action.icon ? (
                <Button.Icon
                  {...regularVariant(action.icon)}
                  sx={{ w: { default: "base.icon.size.200" } }}
                />
              ) : null}
              {action.label}
            </HeroCard.Button>
          ),
        )}

        {/* Secondary actions - ButtCons on the right */}
        {secondaryActions.map((action, i) => (
          <HeroCard.ButtCon
            key={action.action}
            testId={`${idPrefix}__secondary-${i}`}
            {...regularVariant(action.icon)}
            size="medium"
            {...(action.link
              ? {
                  rc: (
                    <a
                      href={action.link?.toString()}
                      onClick={() => handleButtonClick(action)}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }
              : { onClick: () => handleButtonClick(action) })}
          />
        ))}
      </HeroCard>
      {/* Rules Dialog */}
      {quest.rules || quest.actions.Layer3 ? (
        <RulesDialog
          quest={quest}
          isVisible={showRules}
          testId={`${idPrefix}-rules-dialog`}
          onClose={() => {
            analytics.trackCtaClick({
              control: "Close",
              controlType: "Button",
              screen: "QuestTileModal",
            });
            setShowRules(false);
          }}
        />
      ) : null}
    </>
  );
};
