import type {
  PromoTileType,
  QuestTileType,
} from "@imx-tokens-ts/gems-game/domain";

export const TILE_GAP = 24;

export const TILE_DIMENSIONS = {
  PromoTile: {
    width: "350px",
    height: "520px",
  },
  FeaturedQuest: {
    width: "100%",
    height: "520px",
  },
  MainQuest: {
    height: "520px",
    width: "350px",
  },
  SideQuest: {
    height: "360px",
    width: "350px",
  },
  OnboardingQuest: {
    height: "520px",
    width: "350px",
  },
  SeriesQuest: {
    height: "520px",
    width: "350px",
  },
} as const;

export function getTileDimensions(tileType: PromoTileType | QuestTileType): {
  width: string;
  height: string;
  tileGap: string;
} {
  return {
    width: TILE_DIMENSIONS[tileType].width,
    height: TILE_DIMENSIONS[tileType].height,
    tileGap: `${TILE_GAP}px`,
  };
}

export function useTileDimensions(tileType: PromoTileType | QuestTileType) {
  const { width, height, tileGap } = getTileDimensions(tileType);
  return {
    tileWidth: width,
    tileHeight: height,
    tileGap,
  };
}
