import type { Quest } from "@imx-tokens-ts/gems-game/domain";
import {
  type Options,
  actionToIconMapping,
  getAvailablePrimaryAction,
  getSecondaryActionsForGame,
  getSecondaryActionsForSocials,
} from "../utils";
import type {
  PrimaryTileAction,
  SecondaryTileAction,
  TileActions,
} from "./tile-actions";

export function getMainSideQuestPrimaryActions(
  quest: Quest,
  opts: Options,
): PrimaryTileAction[] {
  const actions: PrimaryTileAction[] = [];
  if (opts.isQuestComplete) {
    return actions;
  }

  const ruleAction: PrimaryTileAction = {
    action: "Rules",
    label: "Rules",
    icon: actionToIconMapping.Rules,
  };

  if (quest.isComingSoon()) {
    const availablePrimaryAction = getAvailablePrimaryAction(quest, opts);
    if (availablePrimaryAction) {
      actions.push(availablePrimaryAction);
    }

    return actions;
  }

  if (quest.isLive()) {
    const availablePrimaryAction = getAvailablePrimaryAction(quest, opts);
    if (availablePrimaryAction) {
      actions.push(availablePrimaryAction);
    }

    // if rules are available, show it as primary on large and above screens
    // on featured, this is medium and above screens
    const showRulePrimary = quest.rules && opts.isLargeScreenOrAbove;
    if (showRulePrimary) {
      actions.push(ruleAction);
    }

    return actions;
  }

  if (quest.hasEnded()) {
    // if rules are available, it should show up otherwise fallback to showing a game link
    const gameAction = quest.hasAction("PlayGame")
      ? getAvailablePrimaryAction(quest, opts)
      : null;
    const action = quest.rules ? ruleAction : gameAction;
    if (action) {
      actions.push(action);
    }
    return actions;
  }

  return actions;
}

export function getMainSideQuestSecondaryActions(
  quest: Quest,
  opts: Options,
): SecondaryTileAction[] {
  const actions: SecondaryTileAction[] = [];
  const ruleAction: SecondaryTileAction = {
    action: "Rules",
    icon: actionToIconMapping.Rules,
  };

  const secondaryGameActions = getSecondaryActionsForGame(quest, opts);
  const hasSecondaryGameActions = secondaryGameActions.length > 0;
  const hasRules = !!quest.rules;
  const maxSecondaryActionsCount = 2;
  const gameLinksCount = secondaryGameActions.length;

  if (opts.isQuestComplete) {
    // if there are rules, show it as a secondary action in completed state
    if (hasRules) {
      actions.push(ruleAction);
    }

    // show play game action if available
    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }
    return actions;
  }

  if (quest.isComingSoon()) {
    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }

    // 2 socials possible
    const socialActionsCount =
      maxSecondaryActionsCount - secondaryGameActions.length;
    const socialActions = getSecondaryActionsForSocials(
      quest.actions,
      socialActionsCount,
    );
    if (socialActions.length > 0) {
      actions.push(...socialActions);
    }

    return actions;
  }

  if (quest.isLive()) {
    const shouldShowRuleAction = hasRules && !opts.isLargeScreenOrAbove;
    // if there are rules and screen size is smaller than large, show the rules as secondary action
    if (shouldShowRuleAction) {
      actions.push(ruleAction);
    }

    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }

    const ruleActionCount = shouldShowRuleAction ? 1 : 0;

    // if no rules are available, then game becomes primary action, and there is space for 2 social icons
    // otherwise use remaining space for social icon unused by game links, could be 0 or 1
    const socialActionsCount =
      maxSecondaryActionsCount - gameLinksCount - ruleActionCount;
    const socialActions = getSecondaryActionsForSocials(
      quest.actions,
      socialActionsCount,
    );
    if (socialActions.length > 0) {
      actions.push(...socialActions);
    }
    return actions;
  }

  if (quest.hasEnded()) {
    // if there are rules, game links would be secondary
    if (hasSecondaryGameActions && hasRules) {
      actions.push(...secondaryGameActions);
    }

    // if no rules are available, then game becomes primary action, and there is space for 2 social icons
    // otherwise use remaining space for social icon unused by game links, could be 0 or 1
    const socialActionsCount = hasRules
      ? maxSecondaryActionsCount - gameLinksCount
      : maxSecondaryActionsCount;
    const socialAction = getSecondaryActionsForSocials(
      quest.actions,
      socialActionsCount,
    );
    if (socialAction.length > 0) {
      actions.push(...socialAction);
    }

    return actions;
  }

  return actions;
}

export function getMainSideQuestActions(
  quest: Quest,
  opts: Options,
): TileActions {
  return {
    primaryActions: getMainSideQuestPrimaryActions(quest, opts),
    secondaryActions: getMainSideQuestSecondaryActions(quest, opts),
  };
}
