import { Box } from "@biom3/react";
import type { GameTile } from "@imx-tokens-ts/gems-game/data-access";
import { PromoTileType } from "@imx-tokens-ts/gems-game/domain";
import { Header } from "./Header";
import { Tiles } from "./Tiles";

export type PromoTilesSectionProps = {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  data: GameTile[];
};

export function PromoTilesSection({
  title,
  description,
  data,
}: PromoTilesSectionProps) {
  const tileType = PromoTileType.PromoTile;

  return (
    <Box
      id={`${tileType}__section`}
      testId={`${tileType}__section`}
      sx={{
        mb: "base.spacing.x12",
      }}
    >
      <Header title={title} description={description} testId={tileType} />
      {data.length ? <Tiles data={data} tileType={tileType} /> : null}
    </Box>
  );
}
