import type { Quest } from "@imx-tokens-ts/gems-game/domain";
import {
  type Options,
  actionToIconMapping,
  getAvailablePrimaryAction,
  getSecondaryActionsForGame,
  getSecondaryActionsForSocials,
} from "../utils";
import type {
  PrimaryTileAction,
  SecondaryTileAction,
  TileActions,
} from "./tile-actions";

export function getFeaturedQuestSecondaryActions(
  quest: Quest,
  opts: Options,
): SecondaryTileAction[] {
  const actions: SecondaryTileAction[] = [];
  const ruleAction: SecondaryTileAction = {
    action: "Rules",
    icon: actionToIconMapping.Rules,
  };

  const secondaryGameActions = getSecondaryActionsForGame(quest, opts);
  const hasSecondaryGameActions = secondaryGameActions.length > 0;
  const hasRules = !!quest.rules;

  if (opts.isQuestComplete) {
    // if there are rules, show it as a secondary action in completed state
    if (hasRules) {
      actions.push(ruleAction);
    }

    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }

    return actions;
  }

  if (quest.isComingSoon()) {
    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }
    // 2 socials possible
    const socialActions = getSecondaryActionsForSocials(quest.actions, 2);
    if (socialActions.length > 0) {
      actions.push(...socialActions);
    }

    return actions;
  }

  if (quest.isLive()) {
    const shouldShowRuleAction = hasRules && !opts.isMediumScreenOrAbove;
    // if there are rules and screen size is smaller than medium, show the rules as secondary action
    if (shouldShowRuleAction) {
      actions.push(ruleAction);
    }

    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }

    const socialActionsCount = shouldShowRuleAction ? 1 : 2;
    const socialActions = getSecondaryActionsForSocials(
      quest.actions,
      socialActionsCount,
    );
    if (socialActions.length > 0) {
      actions.push(...socialActions);
    }
    return actions;
  }

  if (quest.hasEnded()) {
    if (hasSecondaryGameActions) {
      actions.push(...secondaryGameActions);
    }
    // if no rules are available, then game becomes primary action, and there is space for 1 more social
    const socialActionsCount = hasRules ? 1 : 2;
    const socialAction = getSecondaryActionsForSocials(
      quest.actions,
      socialActionsCount,
    );
    if (socialAction.length > 0) {
      actions.push(...socialAction);
    }

    return actions;
  }

  return actions;
}

export function getFeaturedQuestPrimaryActions(
  quest: Quest,
  opts: Options,
): PrimaryTileAction[] {
  const actions: PrimaryTileAction[] = [];
  if (opts.isQuestComplete) {
    return actions;
  }

  const ruleAction: PrimaryTileAction = {
    action: "Rules",
    label: "Rules",
    icon: actionToIconMapping.Rules,
  };

  if (quest.isComingSoon()) {
    const availablePrimaryAction = getAvailablePrimaryAction(quest, opts);
    if (availablePrimaryAction) {
      actions.push(availablePrimaryAction);
    }

    return actions;
  }

  if (quest.isLive()) {
    const availablePrimaryAction = getAvailablePrimaryAction(quest, opts);
    if (availablePrimaryAction) {
      actions.push(availablePrimaryAction);
    }

    // if rules are available, show it as primary on medium and above
    const showRulePrimary = quest.rules && opts.isMediumScreenOrAbove;
    if (showRulePrimary) {
      actions.push(ruleAction);
    }

    return actions;
  }

  if (quest.hasEnded()) {
    // if rules are available, it should show up otherwise fallback to showing a game link
    const gameAction = quest.actions.PlayGame
      ? getAvailablePrimaryAction(quest, opts)
      : null;
    const action = quest.rules ? ruleAction : gameAction;
    if (action) {
      actions.push(action);
    }
    return actions;
  }

  // it shouldn't really reach here
  return actions;
}

export function getFeaturedQuestActions(
  quest: Quest,
  opts: Options,
): TileActions {
  return {
    primaryActions: getFeaturedQuestPrimaryActions(quest, opts),
    secondaryActions: getFeaturedQuestSecondaryActions(quest, opts),
  };
}
