import { Video } from "@biom3/react";

import { appConfig } from "@/constants";

const rainingGems = `${appConfig.PLAY_ASSETS_CDN_BASE_URL}/videos/immutable/raining-gems.mp4`;

export function RainingGems() {
  return (
    <Video
      sx={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "fixed",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        zIndex: 999,
        mixBlendMode: "exclusion",
        opacity: 0,
        animation: "fadeIn 1s ease-in-out forwards",
        "@keyframes fadeIn": {
          "0%": { opacity: 0 },
          "100%": { opacity: 1 },
        },
      }}
      mimeType="video/mp4"
      videoUrl={rainingGems}
      autoPlay
      loop
      preload="auto"
      muted
      controls={false}
    />
  );
}
