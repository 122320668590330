import type { Quest } from "@imx-tokens-ts/gems-game/domain";
import {
  type Options,
  actionToIconMapping,
  getSecondaryActionsForSocials,
} from "../utils";
import type {
  PrimaryTileAction,
  SecondaryTileAction,
  TileActions,
} from "./tile-actions";

export function getLayer3SecondaryTileActions(
  quest: Quest,
  opts: Options,
): SecondaryTileAction[] {
  const actions: SecondaryTileAction[] = [];
  const hasGameAction = quest.actions.PlayGame;
  const isFeatured = quest.isFeatured();

  // featured on big screens can have 3, main/side can have 2
  const totalSecondaryActionsPossible =
    isFeatured && opts.isMediumScreenOrAbove ? 3 : 2;
  // make space for the Layer3 link and game link
  const totalSocialActionsPossible =
    totalSecondaryActionsPossible -
    (opts.isQuestComplete ? 1 : 0) -
    (hasGameAction ? 1 : 0);

  if (opts.isQuestComplete) {
    // first link is the Layer3 link
    const layer3Action = {
      action: "Layer3" as const,
      icon: actionToIconMapping.Layer3,
      link: quest.actions.Layer3,
    };
    actions.push(layer3Action);
  }

  if (totalSocialActionsPossible > 0) {
    const socialActions = getSecondaryActionsForSocials(
      quest.actions,
      totalSocialActionsPossible,
    );
    actions.push(...socialActions);
  }

  if (hasGameAction) {
    // last link is the game link
    const gameAction = {
      action: "PlayGame" as const,
      icon: actionToIconMapping.PlayGame,
      link: quest.actions.PlayGame,
    };

    actions.push(gameAction);
  }

  return actions;
}

export function getLayer3PrimaryTileAction(
  quest: Quest,
  isComplete: boolean,
): PrimaryTileAction | null {
  if (isComplete) {
    return null;
  }

  const isFeatured = quest.isFeatured();

  // detect the Quest has started
  if (quest.isLive()) {
    return {
      action: "Layer3",
      label: isFeatured ? "Do quest on Layer3" : "Do quest",
      icon: actionToIconMapping.Layer3,
    };
  }

  if (quest.hasEnded()) {
    return {
      action: "Layer3",
      label: isFeatured ? "View quest on Layer3" : "View quest",
      icon: actionToIconMapping.Layer3,
      link: quest.actions.Layer3,
    };
  }

  // it shouldn't really reach here for a layer3 quest
  return null;
}

export function getLayer3QuestActions(
  quest: Quest,
  opts: Options,
): TileActions {
  const primaryTileAction: PrimaryTileAction | null =
    getLayer3PrimaryTileAction(quest, opts.isQuestComplete);
  const primaryTileActions: PrimaryTileAction[] = primaryTileAction
    ? [primaryTileAction]
    : [];
  return {
    primaryActions: primaryTileActions,
    secondaryActions: getLayer3SecondaryTileActions(quest, opts),
  };
}
