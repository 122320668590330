import { type AllIconKeys, useResponsiveConditional } from "@biom3/react";
import type { Action, Quest } from "@imx-tokens-ts/gems-game/domain";
import { detectUserAgent } from "../utils";
import { getFeaturedQuestActions } from "./featured-quest-actions";
import { getLayer3QuestActions } from "./layer3-actions";
import { getMainSideQuestActions } from "./main-side-quest-actions";
import { getOnboardingQuestActions } from "./onboarding-quest-actions";

export type PrimaryTileAction = {
  action: Action;
  label?: string;
  icon?: AllIconKeys;
  link?: URL;
};

export type SecondaryTileAction = {
  action: Action;
  icon: AllIconKeys;
  link?: URL; // link will not be present if it is a rule dialog action
};

export type TileActions = {
  primaryActions: PrimaryTileAction[];
  secondaryActions: SecondaryTileAction[];
};

export function useGetTileActions(
  quest: Quest,
  isQuestComplete: boolean,
): TileActions {
  const opts = {
    isQuestComplete,
    isMediumScreenOrAbove: useResponsiveConditional("medium"),
    isLargeScreenOrAbove: useResponsiveConditional("large"),
    userAgent: detectUserAgent(
      typeof navigator !== "undefined" ? navigator.userAgent : "Other",
    ),
  };

  if (quest.hasAction("Layer3")) {
    return getLayer3QuestActions(quest, opts);
  }

  if (quest.isFeatured()) {
    return getFeaturedQuestActions(quest, opts);
  }

  if (quest.isOnboardingQuest()) {
    return getOnboardingQuestActions(quest, opts);
  }

  return getMainSideQuestActions(quest, opts);
}
