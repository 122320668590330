import { Badge, type BadgeProps, Box } from "@biom3/react";

export function MultiLine({
  lines,
  testId,
}: {
  lines: string[] | undefined;
  testId: string;
}) {
  return (
    <>
      {lines?.length
        ? lines.map((line, index) => (
            <Box key={`quest-line-${line}`} testId={`${testId}-${index}`}>
              {line}
            </Box>
          ))
        : null}
    </>
  );
}

export function MultiLineWithBadge({
  lines,
  testId,
  badgeProps,
}: {
  lines: string[] | undefined;
  testId: string;
  badgeProps: BadgeProps | null;
}) {
  if (!lines?.length && badgeProps) {
    return <Badge testId={`${testId}-badge`} {...badgeProps} />;
  }
  return (
    <>
      {lines?.length
        ? lines.map((line, index) => (
            <Box
              key={`quest-line-badge-${line}`}
              testId={`${testId}-${index}`}
              sx={{
                display: "flex",
              }}
            >
              {index === 0 && badgeProps ? (
                <Badge
                  testId={`${testId}-badge`}
                  {...badgeProps}
                  sx={{ mr: "base.spacing.x2" }}
                />
              ) : null}
              <Box rc={<span />} testId={`${testId}-${index}-text`}>
                {line}
              </Box>
            </Box>
          ))
        : null}
    </>
  );
}
