import { Box, type DeeplyNestedSx, type SxProps } from "@biom3/react";

const Container = ({
  testId,
  children,
  sx = {},
}: {
  testId?: string;
  children: React.ReactElement | React.ReactElement[];
  sx?: DeeplyNestedSx;
}) => {
  const containerStyle: SxProps = {
    ...sx,
  };
  return (
    <Box sx={containerStyle} testId={testId}>
      {children}
    </Box>
  );
};

export default Container;
