import type { Quest } from "@imx-tokens-ts/gems-game/domain";
import { type Options, getSecondaryActionsForSocials } from "../utils";
import type {
  PrimaryTileAction,
  SecondaryTileAction,
  TileActions,
} from "./tile-actions";

export function getOnboardingQuestPrimaryActions(
  quest: Quest,
  opts: Options,
): PrimaryTileAction[] {
  const actions: PrimaryTileAction[] = [];
  if (opts.isQuestComplete) {
    return actions;
  }

  // should only have a game link to the play details page
  actions.push({
    action: "PlayGame",
    icon: "Add",
    label: "Join the Adventure",
    link: quest.actions.PlayGame,
  });

  return actions;
}

export function getOnboardingQuestSecondaryActions(
  quest: Quest,
  opts: Options,
): SecondaryTileAction[] {
  const actions: SecondaryTileAction[] = [];
  const socialActions = getSecondaryActionsForSocials(quest.actions, 1);

  if (socialActions.length > 0) {
    actions.push(...socialActions);
  }

  return actions;
}

export function getOnboardingQuestActions(
  quest: Quest,
  opts: Options,
): TileActions {
  return {
    primaryActions: getOnboardingQuestPrimaryActions(quest, opts),
    secondaryActions: getOnboardingQuestSecondaryActions(quest, opts),
  };
}
