import { useFlag } from "@/hooks/useFlags";
import { Box } from "@biom3/react";
import type { CarouselContainerProps } from "@components/CarouselContainer/CarouselContainer";
import {
  type LatestEarnings,
  type Quest,
  QuestTileFilterOption,
  type QuestTileType,
  buildQuestsForAvailableFilterOptions,
} from "@imx-tokens-ts/gems-game/domain";
import { type ReactElement, useMemo, useState } from "react";
import { Filters } from "./Filters";
import { Header } from "./Header";
import { Tiles } from "./Tiles";

export type QuestsSectionProps = {
  tileType: QuestTileType;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  filterOptions: QuestTileFilterOption[];
  data?: Quest[]; // TODO: this will make a mandatory prop when Tiles prop goes away
  latestEarnings?: LatestEarnings | undefined;
  isThumbnail?: boolean;
  scrollToIndex?: number;
  Tiles?: ReactElement<CarouselContainerProps>;
  testId?: string;
  autoplay?: boolean;
  questIDs?: string[];
  isLoading: boolean;
  showDivider?: boolean;
};

export function QuestsSection({
  title,
  description,
  tileType,
  filterOptions,
  Tiles: Slides,
  data,
  scrollToIndex,
  latestEarnings,
  isThumbnail = false,
  autoplay,
  testId,
  questIDs,
  isLoading,
  showDivider = true,
}: QuestsSectionProps) {
  // biome-ignore lint/correctness/useExhaustiveDependencies: adding filter options cause unnecessary re-renders
  const questsForAvailableFilterOptions = useMemo(
    () =>
      buildQuestsForAvailableFilterOptions(
        data || [],
        filterOptions,
        latestEarnings,
      ),
    [data, latestEarnings],
  );

  const initialFilterOptions = (() => {
    const initialFilters = [
      QuestTileFilterOption.Live,
      QuestTileFilterOption.ComingSoon,
    ].filter(
      (option) =>
        (questsForAvailableFilterOptions.get(option)?.length || 0) > 0,
    );

    if (initialFilters.length === 0) {
      return [QuestTileFilterOption.All];
    }

    return initialFilters;
  })();

  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<QuestTileFilterOption[]>(initialFilterOptions);

  const takeoverBackgroundEnabled = useFlag("engagementFeaturedQuestThemedBg");

  function applyFilter(selected: QuestTileFilterOption) {
    setSelectedFilterOptions((prevSelected) => {
      // Special handling for "All" filter
      if (selected === QuestTileFilterOption.All) {
        // If "All" is selected, deselect everything else and only select "All"
        return prevSelected.includes(QuestTileFilterOption.All) &&
          prevSelected.length === 1
          ? prevSelected // If "All" is already the only selection, keep it as is
          : [QuestTileFilterOption.All];
      }

      const isAlreadySelected = prevSelected.includes(selected);

      if (isAlreadySelected) {
        const newSelection = prevSelected.filter(
          (option) => option !== selected,
        );

        if (newSelection.length === 0) {
          return prevSelected;
        }

        return newSelection;
      }
      const newSelection = [
        ...prevSelected.filter((opt) => opt !== QuestTileFilterOption.All),
        selected,
      ];
      return newSelection;
    });
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding filter options cause unnecessary re-renders
  const filteredQuests = useMemo(() => {
    // Get quests that match ANY of the selected filters
    const questsByFilterOptions = filterOptions.length
      ? Array.from(
          new Set(
            selectedFilterOptions.flatMap(
              (option) => questsForAvailableFilterOptions.get(option) || [],
            ),
          ),
        )
      : data || [];

    return questIDs
      ? questsByFilterOptions.filter((quest) => questIDs.includes(quest.id)) // TODO: improve performance
      : questsByFilterOptions;
  }, [questsForAvailableFilterOptions, selectedFilterOptions, data, questIDs]);

  if (!filteredQuests.length && !isLoading) {
    return null;
  }

  return (
    <Box
      id={testId ? `${testId}__section` : `${tileType}__section`}
      testId={testId ? `${testId}__section` : `${tileType}__section`}
      sx={{
        mb: "base.spacing.x12",
        mt:
          tileType === "FeaturedQuest" && takeoverBackgroundEnabled
            ? "base.spacing.x30"
            : "",
      }}
    >
      <Header
        showDivider={showDivider}
        title={title}
        description={description}
        testId={testId ? testId : tileType}
      />
      <Filters
        options={[...questsForAvailableFilterOptions.keys()]}
        selectedOptions={selectedFilterOptions}
        testId={testId ? `${testId}__filters` : `${tileType}__filters`}
        applyFilter={(selected) => applyFilter(selected)}
      />
      {filteredQuests.length || isLoading ? (
        <Tiles
          autoplay={autoplay}
          data={filteredQuests}
          latestEarnings={latestEarnings}
          scrollToIndex={scrollToIndex}
          tileType={tileType}
          isLoading={isLoading}
          isThumbnail={isThumbnail}
        />
      ) : null}
    </Box>
  );
}
