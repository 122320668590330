import { CarouselSimple, CarouselThumbnail } from "@biom3/react";
import { CarouselContainer } from "@components/CarouselContainer/CarouselContainer";
import { CarouselThumbnailContainer } from "@components/CarouselContainer/CarouselThumbnailContainer";
import { GameTileComponent } from "@components/GameShowcase";
import { QuestTile } from "@components/QuestTiles/QuestTile/QuestTile";
import { useTileDimensions } from "@hooks/tile-dimensions";
import type { GameTile } from "@imx-tokens-ts/gems-game/data-access";
import {
  type LatestEarnings,
  PromoTileType,
  type Quest,
  type QuestTileType,
} from "@imx-tokens-ts/gems-game/domain";
import { useIsLessThanXLargeScreen } from "@ui-kit/hooks/screenSizeHooks";
import { controlCarouselVideosPlayback } from "@ui-kit/utils/videos";
import type { EmblaCarouselType } from "embla-carousel";
import { useCallback, useEffect, useRef, useState } from "react";

export type TileProps = {
  data: Quest[] | GameTile[];
  tileType: QuestTileType | PromoTileType;
  latestEarnings?: LatestEarnings | undefined;
  scrollToIndex?: number;
  isLoading?: boolean;
  autoplay?: boolean;
  isThumbnail?: boolean;
};

export function Tiles({
  data,
  tileType,
  latestEarnings,
  scrollToIndex,
  isLoading,
  autoplay,
  isThumbnail,
}: TileProps) {
  const [emblaApi, setEmblaApi] = useState<EmblaCarouselType | null>(null);
  const { tileWidth, tileHeight, tileGap } = useTileDimensions(tileType);
  const isLessThanXLargeScreenMode = useIsLessThanXLargeScreen();

  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let controlBarSx;
  if (data.length === 1) {
    // hide navigation bar for featured quests. Ideally, make this dynamic based on what tiles are visible in viewport
    controlBarSx = {
      display: "none",
    };
  }
  const useCarouselThumbnail = !isLessThanXLargeScreenMode; // Use carousel thumbnail on large screens only
  const videoDomRefs = useRef<Record<string, HTMLVideoElement | null>>({});
  const handleSlideChange = useCallback(async () => {
    const index = emblaApi?.selectedScrollSnap() || 0;
    await controlCarouselVideosPlayback(index, videoDomRefs.current);
  }, [emblaApi]);
  const handleInit = useCallback(async () => {
    const index = emblaApi?.selectedScrollSnap() || 0;
    await controlCarouselVideosPlayback(index, videoDomRefs.current);
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    handleInit();
    emblaApi?.on("reInit", handleInit);
    emblaApi?.on("select", handleSlideChange);

    return () => {
      emblaApi?.off("select", handleSlideChange);
      emblaApi?.off("reInit", handleInit);
    };
  }, [emblaApi, handleInit, handleSlideChange]);

  return isThumbnail && useCarouselThumbnail ? (
    <CarouselThumbnailContainer
      getApi={setEmblaApi}
      tileType={tileType}
      scrollToIndex={scrollToIndex}
      isLoading={isLoading}
      autoplay={autoplay}
      {
        // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
        ...(controlBarSx ? (controlBarSx = { controlBarSx }) : {})
      }
      variant={"vertical"}
      thumbSpacing="base.spacing.x2"
    >
      {data.map((tileData, i) => (
        <CarouselThumbnail.Slide
          key={tileData.id}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& > .innerContentContainer": {
              p: "0px",
              width: "100%",
              height: "100%",
            },
          }}
        >
          {tileType === PromoTileType.PromoTile ? (
            <GameTileComponent gameTile={tileData as GameTile} />
          ) : (
            <QuestTile
              quest={tileData as Quest}
              latestEarnings={latestEarnings}
              videoDomRef={(el) => {
                videoDomRefs.current[`slide-${i}-video`] = el ? el : null;
              }}
              isFirstQuest={i === 0}
            />
          )}
        </CarouselThumbnail.Slide>
      ))}
      {data.map((tileData, i) => (
        <CarouselThumbnail.Thumb
          key={tileData.id}
          sx={{
            backgroundColor: "base.color.translucent.inverse.800",
            backdropFilter: "blur(15px)",
          }}
        >
          <CarouselThumbnail.Thumb.Image imageUrl={tileData.logo.toString()} />
          <CarouselThumbnail.Thumb.Text>
            {tileData.caption}
          </CarouselThumbnail.Thumb.Text>
        </CarouselThumbnail.Thumb>
      ))}
    </CarouselThumbnailContainer>
  ) : (
    <CarouselContainer
      getApi={setEmblaApi}
      tileType={tileType}
      slideWidth={tileWidth}
      slideHeight={tileHeight}
      slideSpacing={tileGap}
      scrollToIndex={scrollToIndex}
      isLoading={isLoading}
      autoplay={autoplay}
      {
        // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
        ...(controlBarSx ? (controlBarSx = { controlBarSx }) : {})
      }
    >
      {data.map((tileData, i) => (
        <CarouselSimple.Slide key={tileData.id}>
          {tileType === PromoTileType.PromoTile ? (
            <GameTileComponent gameTile={tileData as GameTile} />
          ) : (
            <QuestTile
              quest={tileData as Quest}
              latestEarnings={latestEarnings}
              videoDomRef={(el) => {
                videoDomRefs.current[`slide-${i}-video`] = el ? el : null;
              }}
              isFirstQuest={i === 0}
            />
          )}
        </CarouselSimple.Slide>
      ))}
    </CarouselContainer>
  );
}
