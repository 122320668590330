import { Button } from "@biom3/react";
import Container from "@components/Container";
import type {
  PromoTileFilterOption,
  QuestTileFilterOption,
} from "@imx-tokens-ts/gems-game/domain";

export type FilterProps<
  T extends QuestTileFilterOption | PromoTileFilterOption,
> = {
  options: T[];
  selectedOptions: T[];
  applyFilter: (selectedOption: T) => void;
  testId: string;
};

export function Filters<
  T extends QuestTileFilterOption | PromoTileFilterOption,
>({ options, selectedOptions, testId, applyFilter }: FilterProps<T>) {
  if (options.length === 0) return null;

  return (
    <Container
      testId={testId}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mb: "base.spacing.x6",
        overflowX: "auto",
        // we want to scroll but hide the scrollbar
        scrollbarWidth: "none" /* for Internet Explorer, Edge */,
        msOverflowStyle: "none" /* for Internet Explorer, Edge */,
        "::-webkit-scrollbar": {
          display: "none" /* for Chrome, Safari, and Opera */,
        },
      }}
    >
      {options.map((option, i) => (
        <Button
          key={option}
          testId={`${testId}--${option.replace(" ", "-")}`}
          sx={{ mr: i !== options.length - 1 ? "base.spacing.x4" : "0px" }}
          variant={selectedOptions.includes(option) ? "primary" : "tertiary"}
          size="small"
          onClick={() => applyFilter(option)}
        >
          {option}
        </Button>
      ))}
    </Container>
  );
}
