import { QuestTileType } from "@/domain/gems-game/domain";
import { useTileAnalytics } from "@/hooks/useQuestAnalytics";
import { Card } from "@biom3/react";
import type { GameTile } from "@imx-tokens-ts/gems-game/data-access";
import { useCallback } from "react";
import { PromoTilesSection } from "./TilesSection/PromoTilesSection";

export const GameTileComponent = ({ gameTile }: { gameTile: GameTile }) => {
  const analytics = useTileAnalytics(gameTile.id, QuestTileType.PromoTile);
  const trackLinkClick = useCallback(
    (gameTileId: string) => {
      analytics.trackCtaClick({
        control: gameTile.buttonText,
        controlType: "Link",
        screen: "GameTile",
        extras: {
          link: gameTile.link,
        },
      });
    },
    [analytics, gameTile.link, gameTile.buttonText],
  );

  return (
    <Card
      testId={`game-tiles-${gameTile.id}`}
      key={gameTile.title}
      elevation={2}
      sx={{ width: "100%", height: "100%" }}
    >
      <Card.AssetImage
        imageUrl={gameTile.logo}
        aspectRatio="3/2"
        relativeImageSizeInLayout="382px"
      />
      <Card.Caption>{gameTile.caption}</Card.Caption>
      <Card.Title>{gameTile.title}</Card.Title>
      <Card.Description>{gameTile.description}</Card.Description>
      <Card.Button
        testId={`game-tiles-button-${gameTile.id}`}
        variant="tertiary"
        rc={
          <a
            href={gameTile.link}
            target="_blank"
            rel={gameTile.isTrusted ? "external" : "noreferrer"}
            referrerPolicy={gameTile.isTrusted ? "origin" : "no-referrer"}
            onClick={() => trackLinkClick(gameTile.id)}
          />
        }
      >
        {gameTile.buttonText}
      </Card.Button>
    </Card>
  );
};

export const GameShowcase = ({ gameTiles }: { gameTiles: GameTile[] }) => {
  return <PromoTilesSection title="Game Promotions." data={gameTiles} />;
};
